import Box from "@mui/material/Box";
import React from "react";
import ListRecipes from "../features/fun/ListRecipes";

const Cooking = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <h1>Cooking Page</h1>
      <p>Learn to cook delicious meals.</p>
      <ListRecipes />
    </Box>
  );
};

export default Cooking;
