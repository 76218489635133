import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import MainLayout from "./layout/MainLayout";
import ComingSoon from "./pages/Coming";
import Cooking from "./pages/Cooking";
import FunTime from "./pages/FunTime";
import HypparHome from "./pages/HypparHome";
import JournalDetail from "./pages/JournalDetail";
import JournalList from "./pages/JournalList";
import MagicTricks from "./pages/MagicTricks";
import Memories from "./pages/Memories";
import Riddle from "./pages/Riddle";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HypparHome />} />
          <Route path="journal" element={<JournalList />} />
          <Route path="/journal/:id" element={<JournalDetail />} />
          <Route path="memories" element={<Memories />} />
          <Route path="fun" element={<FunTime />} />
          <Route path="fun/riddles" element={<Riddle />} />
          <Route path="fun/cooking" element={<Cooking />} />
          <Route path="fun/magic" element={<MagicTricks />} />
          <Route path="coming" element={<ComingSoon />} />
          <Route path="*" element={<JournalList />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
