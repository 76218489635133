import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import Carousel from "react-material-ui-carousel"; // or the correct path to your Carousel component

const HomeCarousel = () => {
  const items = [
    {
      image: "/images/carousel/hanish_bday_2025/gparents_bond.jpg",
      caption: "Bonded with his grandparents during the summer",
    },
    {
      image: "/images/carousel/hanish_bday_2025/swim_meet.jpg",
      caption: "Reached the Swim Force at Goldfish in February 2024",
    },
    {
      image: "/images/carousel/hanish_bday_2025/black_belt.jpg",
      caption: "Proudly acquired his black belt in June 2024",
    },
    {
      image: "/images/carousel/hanish_bday_2025/chess_under_800.jpg",
      caption: "Won the top Under 800 medal in a chess tournament",
    },
    {
      image: "/images/carousel/hanish_bday_2025/5k_run.jpg",
      caption: "Completed his first official 5K run in October 2024",
    },
    {
      image: "/images/carousel/hanish_bday_2025/team_sparing.jpg",
      caption: "Experienced team sparring in a couple of tournaments",
    },
    {
      image: "/images/carousel/hanish_bday_2025/viola_orchestra.jpg",
      caption: "Played his viola in his first orchestra performance",
    },
  ];

  return (
    <Carousel
      interval={3000} // Time (ms) each slide is visible
      animation="slide" // Choose "slide" or "fade"
      indicators={true} // Show bottom indicators
      navButtonsAlwaysVisible // Always show the next/prev buttons
    >
      {items.map((item, i) => (
        <CarouselItem key={i} item={item} />
      ))}
    </Carousel>
  );
};

function CarouselItem({ item }) {
  return (
    <Paper
      sx={{
        position: "relative",
        textAlign: "center",
        color: "#fff",
        overflow: "hidden",
      }}
      elevation={3}
    >
      <img
        src={item.image}
        alt={item.caption}
        style={{
          width: "100%",
          height: "auto",
          display: "block",
          maxHeight: "500px", // Adjust as needed
          objectFit: "cover",
          margin: "0 auto",
        }}
      />
      <Typography
        variant="h6"
        sx={{
          position: "absolute",
          bottom: "16px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "rgba(0,0,0,0.5)",
          padding: "8px 16px",
          borderRadius: "4px",
        }}
      >
        {item.caption}
      </Typography>
    </Paper>
  );
}

export default HomeCarousel;
