import Box from "@mui/material/Box";
import React from "react";
import ListTricks from "../features/fun/ListTricks";

const MagicTricks = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <ListTricks />
    </Box>
  );
};

export default MagicTricks;
