import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FunTime = () => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    // Fetch activities from an API
    fetch("/data/activities.json")
      .then((response) => response.json())
      .then((data) => setActivities(data))
      .catch((error) => console.error("Error fetching activities:", error));
  }, []);

  return (
    <Container>
      <Grid container spacing={4}>
        {activities.map((activity) => (
          <Grid item key={activity.id} xs={12} sm={6} md={4}>
            <Card>
              {/* <CardMedia
                component="img"
                height="140"
                image={activity.image}
                alt={activity.title}
              /> */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {activity.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {activity.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  key={activity.id}
                  component={Link}
                  to={activity.link}
                  color="secondary"
                  sx={{ my: 2, color: "inherit", display: "block" }}
                >
                  {activity.action}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FunTime;
