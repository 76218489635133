import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import HomeCarousel from "../features/home/HomeCarousel";
import HomeComments from "../features/home/HomeComments";

const HypparHome = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <HomeCarousel />
        <Typography variant="h4" gutterBottom>
          Today, our little hero is turning 9, and we’re so excited to celebrate
          his birthday!
        </Typography>
        <Typography variant="body1" paragraph>
          It has been a year filled with exploration, achievements, and the
          growth of many new skills. While he has poured so much energy into
          sharpening those skills, he also made sure to create unforgettable
          memories with his playful spirit in our new home.
        </Typography>
        <Typography variant="body1" paragraph>
          In January 2021, he began his Taekwondo journey and never took his
          eyes off earning a black belt. Through laser-focused dedication, he
          achieved his 1st Degree Black Belt after three years of commitment and
          hard work. He’s not stopping there—after tasting the thrill of
          tournaments, he’s determined to keep going further in his journey.
        </Typography>
        <Typography variant="body1" paragraph>
          He sometimes gives us a hard time, but he’s mature enough to make
          things right and help us feel comfortable again. He’s not slowing down
          his adventurous quest to learn new things, and we’re more than happy
          to invest our time and energy in fueling his passion. We’re incredibly
          proud to watch him grow, and we make sure to enjoy every quality
          moment we spend together.
        </Typography>
        <Typography variant="body1" paragraph>
          Hanish, we wish you a wonderful birthday and bless you to break more
          barriers and reach new goals during your 9th year.
        </Typography>
        <Typography variant="h6" paragraph>
          We love you, our prince! - <i>HYPPAR </i> Family
        </Typography>
        <Divider sx={{ my: 4 }} />
        <HomeComments />
      </Box>
    </Container>
  );
};

export default HypparHome;
