import { ArrowBack, ArrowForward, QuizOutlined } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

const ListRiddles = () => {
  const [riddles, setRiddles] = useState([]);
  const [currentRiddleIndex, setCurrentRiddleIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);

  useEffect(() => {
    fetch("/data/riddles.json")
      .then((response) => response.json())
      .then((data) => setRiddles(data))
      .catch((error) => console.error("Error fetching riddles:", error));
  }, []);

  const handlePrev = () => {
    setCurrentRiddleIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : riddles.length - 1
    );
    setShowAnswer(false);
  };

  const handleNext = () => {
    setCurrentRiddleIndex((prevIndex) =>
      prevIndex < riddles.length - 1 ? prevIndex + 1 : 0
    );
    setShowAnswer(false);
  };

  const currentRiddle = riddles[currentRiddleIndex];

  const renderDifficultyStars = (level) => {
    const levels = {
      Easy: 1,
      Medium: 2,
      Hard: 3,
    };
    const stars = levels[level] || 0;
    return Array.from({ length: stars }).map((_, index) => (
      <StarIcon key={index} color="secondary" />
    ));
  };

  return (
    <Box sx={{ padding: 2 }}>
      {currentRiddle && (
        <Card
          sx={{
            marginBottom: 2,
            padding: 2,
            backgroundColor: "#fff",
            borderRadius: 1,
            position: "relative",
            maxWidth: 600, // Reduce the width of the card
            margin: "0 auto", // Center the card
          }}
        >
          <Tooltip title={`Difficulty: ${currentRiddle.difficulty_level}`}>
            <Box sx={{ position: "absolute", top: 8, right: 8 }}>
              {renderDifficultyStars(currentRiddle.difficulty_level)}
            </Box>
          </Tooltip>
          <CardContent>
            <Typography variant="h5" color="text.secondary">
              {currentRiddle.clue}
            </Typography>
            {showAnswer && (
              <>
                <Typography
                  variant="h6"
                  color="text.primary"
                  sx={{ textAlign: "center", mt: 2 }}
                >
                  <strong>Answer:</strong> {currentRiddle.answer}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 2 }}
                >
                  <strong>Explanation:</strong> {currentRiddle.explanation}
                </Typography>
              </>
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontStyle: "italic" }}
            >
              {currentRiddle.category}
            </Typography>
            <Button
              size="small"
              color="secondary"
              onClick={() => setShowAnswer(!showAnswer)}
              startIcon={showAnswer ? <VisibilityIcon /> : <QuizOutlined />}
            >
              {showAnswer ? "Hide Answer" : "Show Answer"}
            </Button>
          </CardActions>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              color="secondary"
              size="small"
              onClick={handlePrev}
              startIcon={<ArrowBack />}
            >
              Previous
            </Button>
            <Typography variant="body2" color="text.secondary" sx={{ mx: 2 }}>
              {currentRiddleIndex + 1} / {riddles.length}
            </Typography>
            <Button
              color="secondary"
              size="small"
              onClick={handleNext}
              endIcon={<ArrowForward />}
            >
              Next
            </Button>
          </Box>
        </Card>
      )}
    </Box>
  );
};

export default ListRiddles;
