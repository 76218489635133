import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

const ListTricks = () => {
  const [tricks, setTricks] = useState([]);

  useEffect(() => {
    fetch("/data/tricks.json")
      .then((response) => response.json())
      .then((data) => setTricks(data))
      .catch((error) => console.error("Error fetching tricks:", error));
  }, []);

  return (
    <Box sx={{ padding: 2 }}>
      {tricks.map((trick, index) => (
        <Card key={index} sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h5" component="div">
              {trick.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {trick.description}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default ListTricks;
