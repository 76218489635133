import Box from "@mui/material/Box";
import React from "react";
import ListRiddles from "../features/fun/ListRiddles";

const Riddle = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <ListRiddles />
    </Box>
  );
};

export default Riddle;
